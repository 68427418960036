import React, {Fragment} from "react";
import { Grid, Row, Divider, Col } from '@element-softworks/daintree';
import styles from './styles.module.scss';

const FreshApproach = ({values}) => {
	return <div className={styles.valuesSectionContainer}>
		<div className={styles.valuesContainer}>
			<Grid>
				<div className={styles.valuesHeading} id={'values'}>
					<h2>How can LiveYou help me?</h2>
				</div>
				<Divider margin={4}/>
				<Divider xsMargin={0} smMargin={0} mdMargin={4}/>
				{!!values && values?.map(({image, text, title}, index) => (
					<Fragment key={title}>
						{!!index && (
							<Fragment>
								<Divider margin={4}/>
								<Divider xsMargin={0} smMargin={0} mdMargin={4}/>
							</Fragment>
						)}
						<Row end={index % 2 !== 0 && 'xs'} key={title} gutters={false}>
							<Col
								xl={3}
								lg={3}
								md={4}
								sm={12}
								xs={12}
								last={index % 2 !== 0 && 'md'}
								shrink
							>
								<div
									className={`${styles.valuesImageContainer} ${
										index % 2 !== 0 ? styles.valuesRightImage : ''
									}`}
								>
									<img src={image?.publicURL} alt={title}/>
								</div>
							</Col>
							<Col xl={6} lg={6} md={7} sm={12} xs={12}>
								{/*<Divider xsMargin={4} smMargin={4} mdMargin={0} />*/}
								<Divider xsMargin={4} smMargin={4} mdMargin={0}/>
								<div
									className={`${styles.valuesTextContainer} ${
										index % 2 !== 0 ? styles.valuesRightText : ''
									}`}
								>
									<h3>{title}</h3>
									<p>{text}</p>
								</div>
							</Col>
						</Row>
					</Fragment>
				))}
			</Grid>
		</div>
	</div>
};

export default FreshApproach;
